import React, { useState, useEffect } from "react";
import ClientLayout from "../Components/Layout";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Row, Col, Table, Divider, Form, Input, Alert } from "antd";
import "react-dotenv";

const WaterAnalysisCXBYND = () => {
  const [constData, setConstData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    const data = {
      department_name: values["department_name"],
      data: {
        ca: values["ca"],
        alk: values["alk"],
        cl: values["cl"],
        p: values["p"],
      },
    };
    const postConst = () => {
      setSubmitLoading(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}api/wa/params`, data, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "SD-API-KEY": `${process.env.REACT_APP_SD_API_KEY}`,
          },
        })
        .then((res) => {
          if ("msg" in res.data) {
            if (res.data["msg"] === "unauthorized") {
              alert("Unauhtorized request!");
            }
          }
        });

      setSubmitLoading(false);
      setSuccessMsg(true);
      setTimeout(() => {
        form.resetFields();
      }, 500);
    };
    postConst();
  };

  const fetchConst = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}api/wa/params`, {
        headers: {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "SD-API-KEY": `${process.env.REACT_APP_SD_API_KEY}`,
        },
      })
      .then((res) => {
        const res_data = res.data;
        if ("msg" in res.data) {
          if (res.data["msg"] === "unauthorized") {
            alert("Unauhtorized request!");
            return;
          }
        }
        var tmp = [];
        let i = 0;
        while (i < res_data.length) {
          const obj = {
            key: i.toString(),
            lab_name: res_data[i]["name"],
            ca_const: res_data[i]["data"]["ca"],
            alk_const: res_data[i]["data"]["alk"],
            cl_const: res_data[i]["data"]["cl"],
            p_const: res_data[i]["data"]["p"],
          };
          tmp.push(obj);
          i++;
        }
        setConstData(tmp);
      })
      .catch((err) => alert("Error fetching const data.", err));
  };

  const handleClick = (e) => {
    e.preventDefault();
    fetchConst();
    setLoading(true);
  };

  useEffect(() => {
    const timeId = setTimeout(() => {
      setSuccessMsg(false);
    }, 5000);
    return () => {
      clearTimeout(timeId);
    };
  }, [successMsg]);

  const columns = [
    {
      title: "实验室名字",
      dataIndex: "lab_name",
      key: "lab_name",
    },
    {
      title: "钙离子标液浓度",
      dataIndex: "ca_const",
      key: "ca_const",
    },
    {
      title: "碱度标液浓度",
      dataIndex: "alk_const",
      key: "alk_const",
    },
    {
      title: "氯离子标液浓度",
      dataIndex: "cl_const",
      key: "cl_const",
    },
    {
      title: "总磷标液浓度",
      dataIndex: "p_const",
      key: "p_const",
    },
  ];

  return (
    <ClientLayout>
      <Row>
        <Col span={16}>
          <Row>
            <Col offset={1}>
              <h4
                style={{
                  width: "100%",
                  alignItems: "center",
                  marginLeft: "10px",

                  marginTop: "5px",
                }}
              >
                查询标液浓度
              </h4>
            </Col>
            <Col span={6}>
              <Button
                type="primary"
                size="large"
                icon={<SearchOutlined />}
                style={{
                  width: "100%",
                  alignItems: "center",
                  marginLeft: "20px",
                  marginTop: "1px",
                }}
                onClick={handleClick}
              >
                查询
              </Button>
            </Col>
          </Row>
          {/* <Row>
            <Col span={4} offset={1}>
              <Button
                type="primary"
                size="large"
                icon={<SearchOutlined />}
                style={{ width: "100%" }}
                onClick={handleClick}
              >
                查询
              </Button>
            </Col>
          </Row> */}
          <br />
          <Row>
            <Col span={24} offset={1}>
              {loading ? (
                <Table
                  columns={columns}
                  dataSource={constData}
                  style={{
                    width: "90%",
                  }}
                  size="small"
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <Col>
              <h4>更新标液浓度</h4>
            </Col>
          </Row>

          <Form
            name="basic"
            labelCol={{
              span: 7,
            }}
            wrapperCol={{
              span: 10,
            }}
            style={{
              maxWidth: 1000,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="化验室名称"
              name="department_name"
              rules={[
                {
                  required: true,
                  message: "输入化验室名称",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="钙离子标液浓度"
              name="ca"
              rules={[
                {
                  required: true,
                  message: "输入钙离子标液浓度",
                },
              ]}
            >
              <Input suffix="mol/L" />
            </Form.Item>
            <Form.Item
              label="碱度标液浓度"
              name="alk"
              rules={[
                {
                  required: true,
                  message: "输入碱度标液浓度",
                },
              ]}
            >
              <Input suffix="mol/L" />
            </Form.Item>
            <Form.Item
              label="氯离子标液浓度"
              name="cl"
              rules={[
                {
                  required: true,
                  message: "输入氯离子标液浓度",
                },
              ]}
            >
              <Input suffix="mol/L" />
            </Form.Item>
            <Form.Item
              label="总磷标液浓度"
              name="p"
              rules={[
                {
                  required: true,
                  message: "输入总磷标液浓度",
                },
              ]}
            >
              <Input suffix="mol/L" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 2,
                span: 16,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "95%", alignItems: "center" }}
                loading={submitLoading}
              >
                提交
              </Button>
            </Form.Item>
          </Form>
          {successMsg ? (
            <Row>
              <Col>
                <Alert message="修改成功!" type="success" showIcon />
              </Col>
            </Row>
          ) : (
            <span></span>
          )}
        </Col>
      </Row>

      <Divider dashed />
    </ClientLayout>
  );
};
export default WaterAnalysisCXBYND;
