import React from "react";
import ClientLayout from "./Components/Layout";
import Home from "./Pages/Home";

const App = () => {
  return (
    <ClientLayout module="home">
      <Home />
    </ClientLayout>
  );
};
export default App;
