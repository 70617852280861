import React, { useState, useEffect } from "react";
import ClientLayout from "../Components/Layout";
import moment from "moment-timezone";
import axios from "axios";
import AWS from "aws-sdk";
import {
  Button,
  Row,
  Col,
  Divider,
  Form,
  Input,
  Alert,
  Select,
  DatePicker,
  InputNumber,
} from "antd";
import "react-dotenv";
const WaterAnalysisSCBG = () => {
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const postWaReport = async (data) => {
    setSubmitLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}api/wa/create`, data, {
        headers: {
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "SD-API-KEY": `${process.env.REACT_APP_SD_API_KEY}`,
        },
      })
      .then((res) => {
        if ("msg" in res.data) {
          if (res.data["msg"] === "unauthorized") {
            alert("Unauhtorized request!");
          }
        }
      })
      .catch((err) => alert("Error creating a wa report.", err));

    setTimeout(() => {
      setSubmitLoading(false);
    }, 500);
    setSuccessMsg(true);
    setTimeout(() => {
      setSuccessMsg(false);
    }, 1000);
    const report_id = data["analyze_date"] + data["project_name"];
    console.log(report_id);
    const s3 = new AWS.S3({
      accessKeyId: "AKIA2RHTCC5BEBZU3BW4",
      secretAccessKey: "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",
    });
    s3.getObject(
      {
        Bucket: "sendonet",
        Key: `wa_report/${report_id}.docx`,
      },
      (err, data) => {
        if (err) console.log(err);
        const downloadUrl = URL.createObjectURL(
          new Blob([data.Body], { type: "application/docx" })
        );
        console.log(downloadUrl);
        var fileLink = document.createElement("a");
        fileLink.href = downloadUrl;
        fileLink.download = `${report_id}.docx`;
        fileLink.click();
      }
    );
  };
  const onFinish = (values) => {
    const {
      lab_name,
      sent_name,
      project_name,
      technician_name,
      address,
      audit_name,
      phone,
      temperature,
      collect_date,
      analyze_date,
      project_type,
      item_1,
      item_2,
      item_3,
      item_4,
      item_5,
      item_6,
    } = values;
    const post_obj = {
      lab_name,
      sent_name,
      project_name,
      technician_name,
      address,
      audit_name,
      phone,
      temperature,
      collect_date: collect_date.format("YYYY-MM-DD"),
      analyze_date: analyze_date.format("YYYY-MM-DD"),
      project_type,
      raw_data: {},
    };
    const items = [item_1, item_2, item_3, item_4, item_5, item_6];
    let i = 0;
    while (i < items.length) {
      let tmp = [];
      if (items[i] !== undefined) {
        values[`ph_${i + 1}`] || values[`ph_${i + 1}`] === "0"
          ? tmp.push({ ph: parseFloat(values[`ph_${i + 1}`]) })
          : tmp.push({ ph: "" });
        values[`con_${i + 1}`]
          ? tmp.push({ con_val: parseFloat(values[`con_${i + 1}`]) })
          : tmp.push({ con_val: "" });
        values[`ca_${i + 1}`]
          ? tmp.push({
              ca_val: parseFloat(values[`ca_${i + 1}`]),
              ca_vol: parseFloat(values[`ca_vol_${i + 1}`]),
            })
          : tmp.push({
              ca_val: "",
              ca_vol: 15,
            });
        values[`hn_${i + 1}`]
          ? tmp.push({
              hn_val: parseFloat(values[`hn_${i + 1}`]),
              hn_vol: parseFloat(values[`hn_vol_${i + 1}`]),
            })
          : tmp.push({
              hn_val: "",
              hn_vol: 15,
            });
        values[`alk_${i + 1}`]
          ? tmp.push({
              alk_val: parseFloat(values[`alk_${i + 1}`]),
              alk_vol: parseFloat(values[`alk_vol_${i + 1}`]),
            })
          : tmp.push({
              alk_val: "",
              alk_vol: 15,
            });
        values[`cl_${i + 1}`]
          ? tmp.push({
              cl_val: parseFloat(values[`cl_${i + 1}`]),
              cl_vol: parseFloat(values[`cl_vol_${i + 1}`]),
            })
          : tmp.push({
              cl_val: "",
              cl_vol: 15,
            });
        values[`p_${i + 1}`]
          ? tmp.push({
              p_val: parseFloat(values[`p_${i + 1}`]),
              p_vol: parseFloat(values[`p_vol_${i + 1}`]),
            })
          : tmp.push({
              p_val: "",
              p_vol: 15,
            });
        values[`tur_${i + 1}`]
          ? tmp.push({ tur_val: parseFloat(values[`tur_${i + 1}`]) })
          : tmp.push({ tur_val: "" });
        values[`ptsa_${i + 1}`]
          ? tmp.push({ ptsa: parseFloat(values[`ptsa_${i + 1}`]) })
          : tmp.push({ ptsa: "" });
        post_obj["raw_data"][values[`item_${i + 1}`]] = tmp;
      }
      i++;
    }
    postWaReport(post_obj);
  };
  useEffect(() => {
    form.setFieldsValue({
      audit_name: "钱驰名",
      address: "江苏省常州市天宁区省岸里161号",
      analyze_date: moment().tz("Asia/Shanghai"),
      temperature: 45,
      project_type: "O",
      technician_name: "宫主",
      lab_name: "常州申度",
      phone: "0519-81235599",
      sent_name: "项目现场",
      ca_vol_1: 15,
      ca_vol_2: 15,
      ca_vol_3: 15,
      ca_vol_4: 15,
      ca_vol_5: 15,
      ca_vol_6: 15,
      hn_vol_1: 15,
      hn_vol_2: 15,
      hn_vol_3: 15,
      hn_vol_4: 15,
      hn_vol_5: 15,
      hn_vol_6: 15,
      cl_vol_1: 15,
      cl_vol_2: 15,
      cl_vol_3: 15,
      cl_vol_4: 15,
      cl_vol_5: 15,
      cl_vol_6: 15,
      p_vol_1: 15,
      p_vol_2: 15,
      p_vol_3: 15,
      p_vol_4: 15,
      p_vol_5: 15,
      p_vol_6: 15,
      alk_vol_1: 15,
      alk_vol_2: 15,
      alk_vol_3: 15,
      alk_vol_4: 15,
      alk_vol_5: 15,
      alk_vol_6: 15,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ClientLayout>
      <h4>水质报告生成系统</h4>
      <Divider />
      <Form
        form={form}
        onFinish={onFinish}
        style={{
          maxWidth: 2000,
        }}
      >
        <Row name="lab_sent_technician">
          <Col span={6}>
            <Form.Item label="实验室名称" name="lab_name" required={true}>
              <Select
                style={{
                  width: "100%",
                }}
                options={[
                  {
                    value: "常州申度",
                    label: "常州申度",
                  },
                  {
                    value: "通山化验室",
                    label: "通山化验室",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="寄/送样人" name="sent_name">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="检验人" name="technician_name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row name="projectname_date">
          <Col span={6}>
            <Form.Item
              label="项目名称"
              name="project_name"
              rules={[
                {
                  required: true,
                  message: "输入项目名称",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item
              label="取样日期"
              name="collect_date"
              rules={[
                {
                  required: true,
                  message: "输入取样日期",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="化验日期" name="analyze_date">
              <DatePicker style={{ width: "100%" }} format={dateFormat} />
            </Form.Item>
          </Col>
        </Row>
        <Row name="tem_type_path">
          <Col span={6}>
            <Form.Item label="换热温度" name="temperature">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="项目性质" name="project_type">
              <Select
                style={{
                  width: "100%",
                }}
                options={[
                  {
                    value: "N",
                    label: "新项目",
                  },
                  {
                    value: "O",
                    label: "现有项目",
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="电话" name="phone">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row name="address_audit_phone">
          <Col span={13}>
            <Form.Item label="地址" name="address">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} offset={1}>
            <Form.Item label="审核" name="audit_name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Divider dashed />
        <Row name="item_row">
          <Col span={4}>
            <Form.Item
              label="水样1"
              name="item_1"
              rules={[
                {
                  required: true,
                  message: "输入至少一个水样名称",
                },
              ]}
            >
              <Input
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="水样2" name="item_2">
              <Input
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="水样3" name="item_3">
              <Input
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="水样4" name="item_4">
              <Input
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="水样5" name="item_5">
              <Input
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="水样6" name="item_6">
              <Input
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="ph_row">
          <Col span={4}>
            <Form.Item label="pH" name="ph_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ph_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ph_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ph_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ph_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ph_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="con_row">
          <Col span={4}>
            <Form.Item label="电导率" name="con_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="con_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="con_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="con_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="con_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="con_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="ca_row">
          <Col span={3}>
            <Form.Item label="钙硬度" name="ca_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item name="ca_vol_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_vol_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_vol_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_vol_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_vol_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="ca_vol_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="hn_row">
          <Col span={3}>
            <Form.Item label="总硬度" name="hn_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item name="hn_vol_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_vol_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_vol_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_vol_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_vol_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="hn_vol_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="alk_row">
          <Col span={3}>
            <Form.Item label="总碱度" name="alk_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item name="alk_vol_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_vol_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_vol_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_vol_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_vol_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="alk_vol_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="cl_row">
          <Col span={3}>
            <Form.Item label="氯离子" name="cl_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item name="cl_vol_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_vol_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_vol_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_vol_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_vol_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="cl_vol_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="p_row">
          <Col span={3}>
            <Form.Item label="总磷" name="p_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={1}>
            <Form.Item name="p_vol_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_vol_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_vol_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_vol_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_vol_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item name="p_vol_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "80%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="tur_row">
          <Col span={4}>
            <Form.Item label="浊度" name="tur_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tur_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tur_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tur_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tur_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="tur_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row name="ptsa_row">
          <Col span={4}>
            <Form.Item label="PTSA" name="ptsa_1">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ptsa_2">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ptsa_3">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ptsa_4">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ptsa_5">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="ptsa_6">
              <InputNumber
                size="meidum"
                style={{
                  width: "90%",
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider dashed />
        <Row>
          <Col span={12} offset={6}>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", alignItems: "center" }}
              loading={submitLoading}
            >
              提交
            </Button>
          </Col>
        </Row>
        <br />
        {successMsg ? (
          <Row>
            <Col span={24}>
              <Alert message="提交成功!" type="success" showIcon />
            </Col>
          </Row>
        ) : (
          <span></span>
        )}
      </Form>
    </ClientLayout>
  );
};
export default WaterAnalysisSCBG;
