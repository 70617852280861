import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import WaterAnalysisSCBG from "../Pages/WaterAnalysisSCBG";
import WaterAnalysisCXBYND from "../Pages/WaterAnalysisCXBYND";
import WaterAnalysisXGBG from "../Pages/WaterAnalysisXGBG";
import BsType from "../Pages/BsType";

const ClientRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="*" element={<App />} />
        <Route
          path="/water-analysis/create-report"
          element={<WaterAnalysisSCBG />}
        />
        <Route
          path="/water-analysis/modify-report"
          element={<WaterAnalysisXGBG />}
        />
        <Route
          path="/water-analysis/check-constant"
          element={<WaterAnalysisCXBYND />}
        />
        <Route path="bs-type" element={<BsType />} />
      </Routes>
    </BrowserRouter>
  );
};

export default ClientRoute;
