import React, { useEffect, useState } from "react";
import ClientLayout from "../Components/Layout";
import axios from "axios";
import {
  Anchor,
  Row,
  Col,
  Descriptions,
  Select,
  Form,
  InputNumber,
  Cascader,
  Button,
  Divider,
  Spin,
  Input,
  Table,
} from "antd";

const BsType = () => {
  // FORM
  const [form_1] = Form.useForm();
  const [form_2] = Form.useForm();
  const [form_3] = Form.useForm();

  // STATE
  const [pageLoading, setPageLoading] = useState(false);
  const [ctDescData, setCtDescData] = useState([
    {
      key: "1",
      label: "厂牌 Brand",
      children: "",
      span: 1,
    },
    {
      key: "2",
      label: "系列 Tower Ser.",
      children: "",
      span: 1,
    },
    {
      key: "3",
      label: "型号 Model",
      children: "",
      span: 1,
    },
    {
      key: "4",
      label: "描述 Description",
      children: "",
      span: 3,
    },
    {
      key: "5",
      label: "标准水流量 Nminal flowrate tons",
      children: "",
      span: 3,
    },
    {
      key: "6",
      label: "循环水量 Circulating flowrate",
      children: "",
      span: 3,
    },
    {
      key: "8",
      label: "单风室长度 Length per cell",
      children: "",
      span: 1.5,
    },
    {
      key: "9",
      label: "单风室宽度 Width per cell",
      children: "",
      span: 1.5,
    },
    {
      key: "10",
      label: "单风室高度 Height per cell",
      children: "",
      span: 1.5,
    },
    {
      key: "11",
      label: "盘管材质 Coil material",
      children: "",
      span: 1.5,
    },
    {
      key: "12",
      label: "盘管容积 Coil volume",
      children: "",
      span: 1.5,
    },
    {
      key: "13",
      label: "底部水盘保有水量 Volume of spray water basin",
      children: "",
      span: 1.5,
    },
    {
      key: "14",
      label: "喷淋泵喷淋流量 Spray flow rate",
      children: "",
      span: 1.5,
    },
    {
      key: "15",
      label: "框架材质 Frame material",
      children: "",
      span: 1.5,
    },
    {
      key: "16",
      label: "设计温差 Range Design",
      children: "",
      span: 1.5,
    },
    {
      key: "17",
      label: "风室数量 Cell Qty",
      children: "",
      span: 1.5,
    },
    {
      key: "18",
      label: "",
      children:
        "备注：上述信息根据冷却塔厂家的当年最新产品数据，如未及时更新，可能有部分偏差",
      span: 3,
    },
    {
      key: "19",
      label: "",
      children:
        "Note:  Above infor. based on the latest product data of the cooling tower manufacturers in the year, if not updated in time, there may be some deviations",
      span: 3,
    },
  ]);
  const [ctData, setCtData] = useState([]);
  const [wtData, setWtData] = useState([]);
  const [coolingTowerInfo, setCoolingTowerInfo] = useState({
    ct_brands: [],
    ct_serials: [],
    ct_models: [],
  });
  const [cityList, setCityList] = useState([]);
  const [makeUpWaterData, setMakeUpWaterData] = useState([
    {
      key: "1",
      label: "地区 City",
      children: "",
      span: 3,
    },
    {
      key: "2",
      label: "pH",
      children: "",
      span: 1.5,
    },
    {
      key: "3",
      label: "电导率",
      children: "",
      span: 1.5,
    },
    {
      key: "4",
      label: "钙硬度",
      children: "",
      span: 1.5,
    },
    {
      key: "5",
      label: "总碱度",
      children: "",
      span: 1.5,
    },
    {
      key: "6",
      label: "总硬度",
      children: "",
      span: 1.5,
    },
    {
      key: "7",
      label: "氯离子",
      children: "",
      span: 1.5,
    },
    {
      key: "8",
      label: "浊度",
      children: "",
      span: 1.5,
    },
    {
      key: "9",
      label: "总磷",
      children: "",
      span: 1.5,
    },
  ]);

  const [calInputData, setCalInputData] = useState({
    wb_temp: "",
    design_range: "",
    op_time: "",
    material: "",
    exchange_temp: "",
    env_type: "",
    water_type: "",
    cir_vol: "",
    re_vol: "",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [usageData, setUsageData] = useState([]);
  const [calFlag, setCalFlag] = useState(1);

  // INITIAL FETCHDATA
  useEffect(() => {
    const fetchCtMetaData = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}api/dosing/ct_metadata`,
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
              "DOSING-API-KEY": `${process.env.REACT_APP_DOSING_API_KEY}`,
            },
          }
        );
        const brands_list = data.data.brands;
        const brands_collection = brands_list.map((brand) => {
          return {
            value: brand,
            label: brand,
          };
        });

        const serials_list = [];
        for (const element of brands_list) {
          var keys = Object.keys(data.data[element]);
          serials_list.push(...keys);
        }

        const serials_collection = serials_list.map((ser) => {
          return {
            value: ser,
            label: ser,
          };
        });

        const models_list = [];
        for (const brand of brands_list) {
          for (const serial of Object.keys(data.data[brand])) {
            models_list.push(...data.data[brand][serial]);
          }
        }

        const models_collection = models_list.map((mol) => {
          return {
            label: mol,
            value: mol,
          };
        });

        setCoolingTowerInfo({
          ct_brands: brands_collection,
          ct_serials: serials_collection,
          ct_models: models_collection,
        });
      } catch (error) {
        console.error(error.message);
      }
    };
    const fetchCtData = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}api/dosing/ct_data`,
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
              "DOSING-API-KEY": `${process.env.REACT_APP_DOSING_API_KEY}`,
            },
          }
        );
        setCtData(data.data);
      } catch (error) {
        console.error(error.message);
      }
    };
    const fetchWaterArea = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}api/dosing/city_list`,
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
              "DOSING-API-KEY": `${process.env.REACT_APP_DOSING_API_KEY}`,
            },
          }
        );
        const province_list = data.data.province_list;
        const city_list_collection = province_list.map((prov) => {
          const children_list = data.data[prov].map((city) => {
            return {
              label: city,
              value: city,
            };
          });
          return {
            label: prov,
            value: prov,
            children: children_list,
          };
        });
        setCityList(city_list_collection);
      } catch (error) {
        console.error(error.message);
      }
    };
    const fetchWaterData = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_API_URL}api/dosing/water_data`,
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
              "DOSING-API-KEY": `${process.env.REACT_APP_DOSING_API_KEY}`,
            },
          }
        );
        setWtData(data.data);
      } catch (error) {
        console.error(error.message);
      }
    };
    setPageLoading(true);
    fetchCtMetaData();
    fetchCtData();
    fetchWaterArea();
    fetchWaterData();
    setPageLoading(false);
  }, []);

  useEffect(() => {
    const fetchUsageData = async () => {
      try {
        const data = await axios.post(
          `${process.env.REACT_APP_API_URL}api/dosing/cal_usage`,
          calInputData,
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "*",
              "Content-Type": "application/json",
              "DOSING-API-KEY": `${process.env.REACT_APP_DOSING_API_KEY}`,
            },
          }
        );
        console.log("usage Data: ", usageData);
        setUsageData(data.data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchUsageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calFlag]);

  // ITEMS
  const frame_materials = [
    {
      value: "Cu",
      label: "Cu",
    },
    {
      value: "Fe",
      label: "Fe",
    },
  ];

  const coil_materials = [
    {
      value: "Cu",
      label: "Cu",
    },
    {
      value: "Fe",
      label: "Fe",
    },
  ];

  const water_type_collections = [
    {
      value: "non-p",
      label: "含磷",
    },
    {
      value: "p",
      label: "无磷",
    },
  ];

  const ct_summary_items = [
    {
      key: "1",
      label: "厂牌",
      children: "",
    },
    {
      key: "2",
      label: "系列",
      children: "",
    },
    {
      key: "3",
      label: "型号",
      children: "",
    },
    {
      key: "4",
      label: "循环水量",
      children: "",
    },
    {
      key: "5",
      label: "保有水量",
      children: "",
    },
    {
      key: "6",
      label: "框架材质",
      children: "",
    },
    {
      key: "7",
      label: "盘管材质",
      children: "",
    },
    {
      key: "8",
      label: "设计温差",
      children: "",
    },
    {
      key: "9",
      label: "换热温度",
      children: "",
    },
    {
      key: "10",
      label: "描述",
      children: "",
    },
    {
      key: "11",
      label: "风室数量",
      children: "",
    },
  ];

  const wt_summary_items = [
    {
      key: "1",
      label: "地区",
      children: "",
    },
    {
      key: "2",
      label: "排放标准",
      children: "",
    },
    {
      key: "3",
      label: "pH",
      children: "",
    },
    {
      key: "4",
      label: "电导率",
      children: "",
    },
    {
      key: "5",
      label: "该硬度",
      children: "",
    },
    {
      key: "6",
      label: "总碱度",
      children: "",
    },
    {
      key: "7",
      label: "总硬度",
      children: "",
    },
    {
      key: "8",
      label: "氯离子",
      children: "",
    },
    {
      key: "9",
      label: "浊度",
      children: "",
    },
    {
      key: "10",
      label: "总磷",
      children: "",
    },
  ];

  const usage_summary_cols = [
    {
      title: "药剂牌号",
      width: 100,
      dataIndex: "type",
      fixed: "left",
    },
    {
      title: "用途",
      width: 100,
      dataIndex: "func",
      fixed: "left",
    },
    {
      title: "年用量",
      width: 100,
      dataIndex: "qty",
      fixed: "left",
    },
  ];

  const usage_summary_items = [
    {
      key: "1",
      type: "SD-M85",
      func: "常规含磷阻垢缓蚀剂",
      qty: "",
    },
    {
      key: "2",
      type: "SD-M60",
      func: "固体氯片类杀菌剂",
      qty: "",
    },
    {
      key: "3",
      type: "SD-M70",
      func: "异噻类非氧",
      qty: "",
    },
    {
      key: "1",
      type: "SD-M94",
      func: "铜缓",
      qty: "",
    },
  ];

  const displayRender = (labels) => labels[labels.length - 1];

  // FINISH
  const onFinishPartOne = (values) => {
    const b = values.ct_brand;
    const s = values.ct_series;
    const m = values.ct_type;
    const ct_key = b + "|" + s + "|" + m;
    const ct_key_exists = ct_key in ctData;
    if (ct_key_exists) {
      const ct_desc_input = [
        {
          key: "1",
          label: "厂牌 Brand",
          children: b,
          span: 1,
        },
        {
          key: "2",
          label: "系列 Tower Ser.",
          children: s,
          span: 1,
        },
        {
          key: "3",
          label: "型号 Model",
          children: m,
          span: 1,
        },
        {
          key: "4",
          label: "描述 Description",
          children: ctData[ct_key]["description"],
          span: 3,
        },
        {
          key: "5",
          label: "标准水流量 Nminal flowrate tons",
          children: ctData[ct_key]["flowrate_tons"],
          span: 3,
        },
        {
          key: "6",
          label: "循环水量 Circulating flowrate",
          children: values.circulating_flow_rate,
          span: 3,
        },
        {
          key: "8",
          label: "单风室长度 Length per cell",
          children: ctData[ct_key]["length"] + " mm",
          span: 1.5,
        },
        {
          key: "9",
          label: "单风室宽度 Width per cell",
          children: ctData[ct_key]["width"] + " mm",
          span: 1.5,
        },
        {
          key: "10",
          label: "单风室高度 Height per cell",
          children: ctData[ct_key]["height"] + " mm",
          span: 1.5,
        },
        {
          key: "11",
          label: "盘管材质 Coil material",
          children: ctData[ct_key]["coil_material"],
          span: 1.5,
        },
        {
          key: "12",
          label: "盘管容积 Coil volume",
          children: ctData[ct_key]["coil_volume"] + " m3",
          span: 1.5,
        },
        {
          key: "13",
          label: "底部水盘保有水量 Volume of spray water basin",
          children: ctData[ct_key]["water_basin_volume"] + " m3",
          span: 1.5,
        },
        {
          key: "14",
          label: "喷淋泵喷淋流量 Spray flow rate",
          children: ctData[ct_key]["spray_flow_rate"] + " m3",
          span: 1.5,
        },
        {
          key: "15",
          label: "框架材质 Frame material",
          children: values.frame_materials,
          span: 1.5,
        },
        {
          key: "16",
          label: "设计温差 Range Design",
          children: values.ct_temp_diff,
          span: 1.5,
        },
        {
          key: "17",
          label: "风室数量 Cell Qty",
          children: values.ct_number,
          span: 1.5,
        },
        {
          key: "18",
          label: "",
          children:
            "备注：上述信息根据冷却塔厂家的当年最新产品数据，如未及时更新，可能有部分偏差",
          span: 3,
        },
        {
          key: "19",
          label: "",
          children:
            "Note:  Above infor. based on the latest product data of the cooling tower manufacturers in the year, if not updated in time, there may be some deviations",
          span: 3,
        },
      ];
      setCtDescData(ct_desc_input);
    } else {
      const ct_desc_input = [
        {
          key: "1",
          label: "厂牌 Brand",
          children: b,
          span: 1,
        },
        {
          key: "2",
          label: "系列 Tower Ser.",
          children: s,
          span: 1,
        },
        {
          key: "3",
          label: "型号 Model",
          children: m,
          span: 1,
        },
        {
          key: "4",
          label: "描述 Description",
          children: "n/a",
          span: 3,
        },
        {
          key: "5",
          label: "标准水流量 Nminal flowrate tons",
          children: "n/a",
          span: 3,
        },
        {
          key: "6",
          label: "循环水量 Circulating flowrate",
          children: values.circulating_flow_rate,
          span: 3,
        },
        {
          key: "8",
          label: "单风室长度 Length per cell",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "9",
          label: "单风室宽度 Width per cell",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "10",
          label: "单风室高度 Height per cell",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "11",
          label: "盘管材质 Coil material",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "12",
          label: "盘管容积 Coil volume",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "13",
          label: "底部水盘保有水量 Volume of spray water basin",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "14",
          label: "喷淋泵喷淋流量 Spray flow rate",
          children: "n/a",
          span: 1.5,
        },
        {
          key: "15",
          label: "框架材质 Frame material",
          children: values.frame_materials,
          span: 1.5,
        },
        {
          key: "16",
          label: "设计温差 Range Design",
          children: values.ct_temp_diff,
          span: 1.5,
        },
        {
          key: "17",
          label: "风室数量 Cell Qty",
          children: values.ct_number,
          span: 1.5,
        },
        {
          key: "18",
          label: "",
          children:
            "备注：上述信息根据冷却塔厂家的当年最新产品数据，如未及时更新，可能有部分偏差",
          span: 3,
        },
        {
          key: "19",
          label: "",
          children:
            "Note:  Above infor. based on the latest product data of the cooling tower manufacturers in the year, if not updated in time, there may be some deviations",
          span: 3,
        },
      ];
      setCtDescData(ct_desc_input);
    }
    const single_re_vol = parseFloat(values.ct_reverse_volume);
    const no_cell = parseInt(values.ct_number);
    const single_cir_vol = parseFloat(values.circulating_flow_rate);
    const tot_re_vol = single_re_vol * no_cell;
    const tot_cir_vol = single_cir_vol * no_cell;
    const frame_material = values.frame_materials;
    const coil_material = values.coil_materials;
    var exchange_temp = "a";
    var material_type = "o";
    if (parseFloat(values.exchange_temp) > 45) {
      exchange_temp = "b";
    }
    if (coil_material === "Cu" || frame_material === "Cu") {
      material_type = "Cu";
    }

    setCalInputData((prevState) => ({
      ...prevState,
      cir_vol: tot_cir_vol,
      re_vol: tot_re_vol,
      exchange_temp: exchange_temp,
      material: material_type,
      design_range: values.ct_temp_diff,
    }));
  };
  const onFinishPartTwo = (values) => {
    const city_key = values.makeup_water_type.join("|");
    const city_water_desc_input = [
      {
        key: "1",
        label: "地区 City",
        children: values.makeup_water_type[1],
        span: 3,
      },
      {
        key: "2",
        label: "pH",
        children: wtData[city_key]["ph"],
        span: 1.5,
      },
      {
        key: "3",
        label: "电导率",
        children: wtData[city_key]["con"],
        span: 1.5,
      },
      {
        key: "4",
        label: "钙硬度",
        children: wtData[city_key]["ca"],
        span: 1.5,
      },
      {
        key: "5",
        label: "总碱度",
        children: wtData[city_key]["alk"],
        span: 1.5,
      },
      {
        key: "6",
        label: "总硬度",
        children: wtData[city_key]["hn"],
        span: 1.5,
      },
      {
        key: "7",
        label: "氯离子",
        children: wtData[city_key]["cl"],
        span: 1.5,
      },
      {
        key: "8",
        label: "浊度",
        children: wtData[city_key]["tur"],
        span: 1.5,
      },
      {
        key: "9",
        label: "总磷",
        children: wtData[city_key]["p"],
        span: 1.5,
      },
    ];
    setMakeUpWaterData(city_water_desc_input);
    setCalInputData((prevState) => ({
      ...prevState,
      env_type: values.env_type,
      water_type: wtData[city_key]["water_type"],
      wb_temp: wtData[city_key]["wb_temp"],
    }));
  };
  const onFinishPartThree = (values) => {
    setCalInputData((prevState) => ({
      ...prevState,
      op_time: values.op_time,
    }));

    console.log("cal input data: ", calInputData);
    setCalFlag(calFlag + 1);
    console.log("ct data: ", ctData);
  };

  return (
    <ClientLayout module="water">
      <h1
        style={{
          width: "90%",
          alignItems: "center",
          marginLeft: "10px",
          marginBottom: "25px",
          marginTop: "5px",
        }}
      >
        密闭式冷却塔(closed circuit cooling tower)药剂选型系统
      </h1>
      {pageLoading ? (
        <Spin />
      ) : (
        <Row>
          <Col span={22}>
            <Form
              form={form_1}
              onFinish={onFinishPartOne}
              style={{
                maxWidth: 2000,
              }}
            >
              <div
                id="part-1"
                style={{
                  height: "150vh",
                  background: "rgba(128,128,128,0.05)",
                }}
              >
                <Row>
                  <Col span={8}>
                    <Row>
                      <h5
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "5px",
                          marginTop: "50px",
                        }}
                      >
                        冷却塔信息 Cooling Tower Info.
                      </h5>
                    </Row>
                    <Row>
                      <Form.Item
                        label="厂牌 Brand"
                        name="ct_brand"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Select options={coolingTowerInfo.ct_brands} />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="系列 Ser."
                        name="ct_series"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Select options={coolingTowerInfo.ct_serials} />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="型号 Model"
                        name="ct_type"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Select options={coolingTowerInfo.ct_models} />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="风室数 Cell Qty"
                        name="ct_number"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Row>
                    <h5
                      style={{
                        width: "90%",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      计算数据 Key Parameters
                    </h5>
                    <Row>
                      <Form.Item
                        label="保有水量 Reservation Volume"
                        name="ct_reverse_volume"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          addonAfter="m³"
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="设计温差 Range Design"
                        name="ct_temp_diff"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          addonAfter="°C"
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="框架材质 Frame Material"
                        name="frame_materials"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Select options={frame_materials} />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="盘管材质 Coil Material"
                        name="coil_materials"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Select options={coil_materials} />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="换热温度 Exchange Temp"
                        name="  "
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        <Input addonAfter="°C" />
                      </Form.Item>
                    </Row>
                    <Divider dashed orientation="left" plain>
                      Optional
                    </Divider>
                    <Row>
                      <Form.Item
                        label="循环水量 Circulating Flow Rate"
                        name="circulating_flow_rate"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "5px",
                          marginTop: "0px",
                        }}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          addonAfter="m³/h"
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        查询 Sumbit
                      </Button>
                    </Row>
                    <Divider />
                  </Col>
                  <Col span={16}>
                    <Descriptions
                      title="Tower Description and Basic Data"
                      bordered
                      size="middle"
                      items={ctDescData}
                      style={{
                        width: "98%",
                        alignItems: "center",
                        marginLeft: "2px",
                        marginBottom: "5px",
                        marginTop: "50px",
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
            <Form
              form={form_2}
              onFinish={onFinishPartTwo}
              style={{
                maxWidth: 2000,
              }}
            >
              <div
                id="part-2"
                style={{ height: "60vh", background: "rgba(255,148,0,0.02)" }}
              >
                <Row>
                  <Col span={8}>
                    <Row>
                      <h5
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "5px",
                          marginTop: "50px",
                        }}
                      >
                        水质 Water Quality
                      </h5>
                    </Row>
                    <Row>
                      <Form.Item
                        label="补水 Make Up"
                        name="makeup_water_type"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <Cascader
                          options={cityList}
                          displayRender={displayRender}
                          expandTrigger="hover"
                        />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item
                        label="排放标准 Standards"
                        name="env_type"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "5px",
                          marginTop: "5px",
                        }}
                      >
                        <Select options={water_type_collections} />
                      </Form.Item>
                    </Row>
                    <Row>
                      <Button
                        id="parttwo"
                        type="primary"
                        htmlType="submit"
                        style={{
                          width: "90%",
                          alignItems: "center",
                          marginLeft: "10px",
                          marginBottom: "15px",
                          marginTop: "15px",
                        }}
                      >
                        查询 Sumbit
                      </Button>
                    </Row>
                  </Col>
                  <Col span={16}>
                    <Descriptions
                      size="middle"
                      style={{
                        width: "98%",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "25px",
                        marginTop: "50px",
                      }}
                      title="Makeup Water Info"
                      bordered
                      items={makeUpWaterData}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
            <Form
              form={form_3}
              onFinish={onFinishPartThree}
              style={{
                maxWidth: 2000,
              }}
            >
              <div
                id="part-3"
                style={{ height: "110vh", background: "rgba(0,0,255,0.02)" }}
              >
                <Row>
                  <h5
                    style={{
                      width: "90%",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginBottom: "5px",
                      marginTop: "50px",
                    }}
                  >
                    客户需求及预算 USER'S INFO & PROPOSAL
                  </h5>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="客户名称"
                      name="client_name"
                      style={{
                        width: "90%",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item
                      label="地址"
                      name="client_address"
                      style={{
                        width: "90%",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      label="年运行时间"
                      name="op_time"
                      style={{
                        width: "90%",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "5px",
                        marginTop: "15px",
                      }}
                    >
                      <Input addonAfter="h" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Button
                    id="partthree"
                    type="primary"
                    htmlType="submit"
                    style={{
                      width: "90%",
                      alignItems: "center",
                      marginLeft: "10px",
                      marginBottom: "15px",
                      marginTop: "15px",
                    }}
                  >
                    计算 submit
                  </Button>
                </Row>
                <hr />
                <Row>
                  <Descriptions
                    title="Cooling Tower Info Summary"
                    layout="vertical"
                    items={ct_summary_items}
                    size="small"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      marginLeft: "30px",
                      marginBottom: "0px",
                      marginTop: "15px",
                    }}
                  />
                </Row>
                <hr />
                <Row>
                  <Descriptions
                    title="Make Up Info Summary"
                    layout="vertical"
                    items={wt_summary_items}
                    size="small"
                    style={{
                      width: "100%",
                      alignItems: "center",
                      marginLeft: "30px",
                      marginBottom: "15px",
                      marginTop: "0px",
                    }}
                  />
                </Row>
                <hr />
                <Table
                  size="small"
                  columns={usage_summary_cols}
                  dataSource={usage_summary_items}
                  style={{
                    width: "95%",
                    alignItems: "center",
                    marginLeft: "30px",
                    marginBottom: "15px",
                    marginTop: "0px",
                  }}
                />
                {/* <Row>
                  {Object.entries(usageData).map(([key, val]) => (
                    <h1 key={key}>
                      {key}: {val}
                    </h1>
                  ))}
                </Row> */}
              </div>
            </Form>

            <div
              id="part-4"
              style={{ height: "100vh", background: "rgba(0,0,205,0.02)" }}
            />
            <div
              id="part-5"
              style={{ height: "100vh", background: "rgba(108,110,25,0.02)" }}
            />
          </Col>

          <Col span={2}>
            <Anchor
              items={[
                {
                  key: "part-1",
                  href: "#part-1",
                  title: "冷却塔参数",
                },
                {
                  key: "part-2",
                  href: "#part-2",
                  title: "循环水参数",
                },
                {
                  key: "part-3",
                  href: "#part-3",
                  title: "药剂用量",
                },
                {
                  key: "part-4",
                  href: "#part-4",
                  title: "基本费用",
                },
                {
                  key: "part-5",
                  href: "#part-5",
                  title: "报价文件",
                },
              ]}
            />
          </Col>
        </Row>
      )}
    </ClientLayout>
  );
};
export default BsType;
