import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import ClientRoute from "./Routes/Routes";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-ynamltk9.us.auth0.com"
      clientId="On0HgqB2HRqWP3WGv52DAxDJUJw79nQ2"
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
    >
      <ClientRoute>
        <App />
      </ClientRoute>
    </Auth0Provider>
  </React.StrictMode>
);
