import React from "react";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import {
  MailOutlined,
  ExperimentOutlined,
  SelectOutlined,
  FileWordOutlined,
} from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Layout, Menu, theme, Button, Result, Space } from "antd";
const { Header, Content } = Layout;

const items = [
  {
    label: "主页",
    key: "home",
    icon: <MailOutlined />,
  },
  {
    label: "水质分析",
    key: "szfx",
    icon: <ExperimentOutlined />,
    children: [
      {
        label: "生成水质分析报告",
        key: "scszfxbg",
      },
      {
        label: "修改水质分析报告",
        key: "xgszfxbg",
      },
      {
        label: "查询/更新标液浓度",
        key: "cxbynd",
      },
    ],
  },
  {
    label: "服务报告",
    key: "report",
    icon: <FileWordOutlined />,
  },
  {
    label: "闭式塔药剂选型",
    key: "bs_type",
    icon: <SelectOutlined />,
  },
];

const ClientLayout = ({ children }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const navigation = [
    { label: "主页", key: "home", target: "/" },
    {
      label: "生成水质报告",
      key: "scszfxbg",
      target: "/water-analysis/create-report",
    },
    {
      label: "修改水质分析报告",
      key: "xgszfxbg",
      target: "/water-analysis/modify-report",
    },
    {
      label: "查询/更新标液浓度",
      key: "cxbynd",
      target: "/water-analysis/check-constant",
    },
    { label: "闭式塔药剂选型", key: "bs_type", target: "/bs-type" },
  ];

  const navigate = useNavigate();
  const onClick = (e) => {
    // console.log("click ", e);
    const { target } = navigation.find((item) => item.key === e.key) || {};
    if (target) {
      navigate(target);
    }
  };
  const { isAuthenticated, isLoading, logout, loginWithRedirect } = useAuth0();
  // console.log(isAuthenticated, user);

  if (isLoading) {
    return <div>Loading ...</div>;
  }
  return (
    <Layout>
      <Header
        style={{
          display: "flex",
          alignItems: "center",
          background: "#fff",
        }}
      >
        <div className="demo-logo" />
        <Space>
          <Menu onClick={onClick} mode="horizontal" items={items}></Menu>

          <Button
            shape="round"
            size="small"
            // style={{ float: "right" }}
            onClick={() => loginWithRedirect()}
          >
            登陆
          </Button>
          <Button
            shape="round"
            size="small"
            style={{ float: "right" }}
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          >
            退出
          </Button>
        </Space>
      </Header>
      <Content
        style={{
          padding: "0 50px",
        }}
      >
        <br />
        <Layout
          style={{
            padding: "24px 0",
            background: colorBgContainer,
          }}
        >
          {isAuthenticated ? (
            <Content
              style={{
                padding: "0 24px",
                minHeight: 280,
              }}
            >
              {children}
            </Content>
          ) : (
            <Result
              status="error"
              title="未登录或无权限访问，请联系管理员"
            ></Result>
          )}
        </Layout>
      </Content>
      <Footer />
    </Layout>
  );
};

export default ClientLayout;
